import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Eventos } from '../models/eventos';
import { environment } from '../../environments/environment';
import { RegistrosEventos } from '../models/registroseventos';

moment.locale('en');

@Injectable({
  providedIn: 'root'
})

export class EventosService {

  constructor(private http: HttpClient) { }
  
  getEventos(): Observable<Eventos[]> {
    return this.http.get<any>(`${environment.url_api}getEvents`).pipe(
      map(result => {
        return JSON.parse(JSON.stringify(result.Eventos)).map(item => {                
          return new Eventos(
            item.id_evento,
            item.nombre,
            item.fecha,
            item.hora,
            item.lugar,
            item.descripcion,
            item.link,
            item.banner,
            item.secretaria,
            item.createAt,
            item.user,
            item.status,
          ); 
        });
      })
    );
  }

  addNewEvent(ev: Eventos){
    let data = {
      id_evento: ev.id_evento,
      nombre: ev.nombre,
      fecha: ev.fecha,
      hora: ev.hora,
      lugar: ev.lugar,
      descripcion: ev.descripcion,
      link: ev.link,
      banner: ev.banner,
      secretaria: ev.secretaria,
      createAt: ev.createAt,
      user: ev.user,
      status: ev.status,
    }

    console.log(data);
    
    return this.http.post<any>(`${environment.url_api}addEvent`, data).pipe(
      map(result => {        
        return result.NuevoTracto;
      })
    );  
  }



  getRegistrosFuncionarios(enlace: String): Observable<RegistrosEventos[]> {
    return this.http.get<any>(`${environment.url_api}getRegisterFuncionarios/${enlace}`).pipe(
      map(result => {
        return JSON.parse(JSON.stringify(result.RegistrosFuncionarios)).map(item => {                
          return new RegistrosEventos(
            item.id_registroevento,
            item.evento, 
            item.nombre, 
            item.apellidoP, 
            item.apellidoM, 
            item.edad, 
            item.correo, 
            item.telefono, 
            item.whatsapp, 
            item.fb, 
            item.ig, 
            item.li, 
            item.yt, 
            item.tw, 
            item.tiktok, 
            item.ttv, 
            item.calle, 
            item.colonia, 
            item.municipio, 
            item.cp, 
            item.enlace, 
            item.ine, 
            item.gestion, 
            item.ubicacion, 
            item.descripciongestion, 
            item.apuno, 
            item.apdos, 
            item.aptres, 
            item.apcuatro, 
            item.apcinco, 
            item.createAt, 
            item.user, 
          ); 
        });
      })
    );
  }


  getRegistrosEvento(): Observable<RegistrosEventos[]> {
    return this.http.get<any>(`${environment.url_api}getRegisterEvents`).pipe(
      map(result => {
        return JSON.parse(JSON.stringify(result.RegistroEventos)).map(item => {                
          return new RegistrosEventos(
            item.id_registroevento,
            item.evento, 
            item.nombre, 
            item.apellidoP, 
            item.apellidoM, 
            item.edad, 
            item.correo, 
            item.telefono, 
            item.whatsapp, 
            item.fb, 
            item.ig, 
            item.li, 
            item.yt, 
            item.tw, 
            item.tiktok, 
            item.ttv, 
            item.calle, 
            item.colonia, 
            item.municipio, 
            item.cp, 
            item.enlace, 
            item.ine, 
            item.gestion, 
            item.ubicacion, 
            item.descripciongestion, 
            item.apuno, 
            item.apdos, 
            item.aptres, 
            item.apcuatro, 
            item.apcinco, 
            item.createAt, 
            item.user, 
          ); 
        });
      })
    );
  }

}