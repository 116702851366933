export class Prep{
    constructor(
        public uid: string,
        public alianza: string,
        public candidatura: string,
        public casilla: string,
        public fecha: string,
        public image: string,
        public latitud: string,
        public longitud: string,
        public mc: string,
        public morena: string,
        public pan: string,
        public pes: string,
        public prd: string,
        public pri: string,
        public pt: string,
        public pv: string,
        public rsp: string,
        public seccion: string,
        public total: string,
        public uidUser: string,
    ){}
}