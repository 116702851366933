import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Zonas } from '../models/zonas';
import { Secciones } from '../models/secciones';
import { Colonias } from '../models/colonias';
import { Micros } from '../models/micros';
import { Necesidades } from '../models/necesidades';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  
  constructor(private http: HttpClient) { 

  }

  // ZONAS
  getChartZonas(): Observable<Zonas[]> {
    return this.http.get<any>(`${environment.url_api}chartsZonas`).pipe(
      map(result => {
        return JSON.parse(JSON.stringify(result.ChartZ)).map(item1 => {                
          return new Zonas(
            item1.x,
            item1.y,
          ); 
        });
      })
    );
  }

  // SECCIONES
  getChartSecciones(): Observable<Secciones[]> {
    return this.http.get<any>(`${environment.url_api}chartsZonas`).pipe(
      map(result => {
        return JSON.parse(JSON.stringify(result.ChartS)).map(item1 => {                
          return new Secciones(
            item1.x,
            item1.y,
          ); 
        });
      })
    );
  }

  // MICROS
  getChartMicros(): Observable<Micros[]> { 
    return this.http.get<any>(`${environment.url_api}chartsZonas`).pipe(
      map(result => {
        return JSON.parse(JSON.stringify(result.ChartM)).map(item1 => {                
          return new Micros(
            item1.x,
            item1.y,
            item1.z
          ); 
        });
      })
    );
  }
  
  // COLONIAS
  getChartColonias(): Observable<Colonias[]> {
    return this.http.get<any>(`${environment.url_api}chartsZonas`).pipe(
      map(result => {
        return JSON.parse(JSON.stringify(result.ChartC)).map(item1 => {                
          return new Colonias(
            item1.x,
            item1.y,
          ); 
        });
      })
    );
  }

  // NECESIDADES
  getChartNecesidades(): Observable<Necesidades[]> { 
    return this.http.get<any>(`${environment.url_api}chartsZonas`).pipe(
      map(result => {
        return JSON.parse(JSON.stringify(result.ChartN)).map(item1 => {                
          return new Necesidades(
            item1.x,
            item1.y,
          ); 
        });
      })
    );
  }
  
  // OTRA VAINA ヽ(✿ﾟ▽ﾟ)ノ
  totalUsuarios() { 
    return this.http.get<any>(`${environment.url_api}usuarios/count/totales`).pipe(
      map(data => {  
        return data;
      })
    );
  }


}
