import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as moment from 'moment'
import { Casillas, Incidencias } from '../models/casillas';
import { Prep } from '../models/prep';

moment.locale('en');

@Injectable({
  providedIn: 'root'
})

export class CasillasService {

  constructor(
    private http: HttpClient
  ) { }
  
  getCasillas(): Observable<Casillas[]> {
    return this.http.get<any>(`${environment.url_api}getAperturaCasillas`).pipe(
      map(result => {
        return JSON.parse(JSON.stringify(result.Casillas)).map(item => {                
          return new Casillas(
            item.id,
            item.data.cantar,
            item.data.createAt,
            item.data.fechaApertura,
            item.data.latitud,
            item.data.longitud,
            item.data.seccion,
            item.data.tipoCasilla,
            item.data.uidUser
          ); 
        });
      })
    );
  }

  getIncidencias(): Observable<Incidencias[]> {
    return this.http.get<any>(`${environment.url_api}getIncidencias`).pipe(
      map(result => {
        return JSON.parse(JSON.stringify(result.Incidencias)).map(item => {                
          return new Incidencias(
            item.id,
            item.data.createAt,
            item.data.incidencia,
            item.data.latitud,
            item.data.longitud,
            item.data.uidUser
          ); 
        });
      })
    );
  }

  getLastIncidencias(): Observable<Incidencias[]> {
    return this.http.get<any>(`${environment.url_api}lastIncidencia`).pipe(
      map(result => {
        return JSON.parse(JSON.stringify(result.Incidencias)).map(item => {                
          return new Incidencias(
            item.id,
            item.data.createAt,
            item.data.incidencia,
            item.data.latitud,
            item.data.longitud,
            item.data.uidUser
          ); 
        });
      })
    );
  }

  getPREP(): Observable<Prep[]> {
    return this.http.get<any>(`${environment.url_api}prep`).pipe(
      map(result => {
        return JSON.parse(JSON.stringify(result.PREP)).map(item => {                
          return new Prep(
            item.id,
            item.data.alianza,
            item.data.candidatura,
            item.data.casilla,
            item.data.fecha,
            item.data.image,
            item.data.latitud,
            item.data.longitud,
            item.data.mc,
            item.data.morena,
            item.data.pan,
            item.data.pes,
            item.data.prd,
            item.data.pri,
            item.data.pt,
            item.data.pv,
            item.data.rsp,
            item.data.seccion,
            item.data.total,
            item.data.uidUser,
          ); 
        });
      })
    );
  }

}