export class Eventos{
    constructor(
        public id_evento: string,
        public nombre: string,
        public fecha: string,
        public hora: string,
        public lugar: string,
        public descripcion: string,
        public link: string,
        public banner: string,
        public secretaria: string,
        public createAt: string,
        public user: string,
        public status: string,
    ){}
}






