export class Casillas{
    constructor(
        public uid: string,
        public cantar: string,
        public createAt: string,
        public fechaApertura: string,
        public latitud: string,
        public longitud: string,
        public seccion: string,
        public tipoCasilla: string,
        public uidUser: string,
    ){}
}

export class Incidencias{
    constructor(
        public uid: string,
        public createAt: string,
        public incidencia: string,
        public latitud: string,
        public longitud: string,
        public uidUser: string,
    ){}
}