export class RegistrosEventos{
    constructor(
        public id_registroevento: string,
        public evento: string, 
        public nombre: string, 
        public apellidoP: string, 
        public apellidoM: string, 
        public edad: string, 
        public correo: string, 
        public telefono: string, 
        public whatsapp: string, 
        public fb: string, 
        public ig: string, 
        public li: string, 
        public yt: string, 
        public tw: string, 
        public tiktok: string, 
        public ttv: string, 
        public calle: string, 
        public colonia: string, 
        public municipio: string, 
        public cp: string, 
        public enlace: string, 
        public ine: string, 
        public gestion: string, 
        public ubicacion: string, 
        public descripciongestion: string, 
        public apuno: string, 
        public apdos: string, 
        public aptres: string, 
        public apcuatro: string, 
        public apcinco: string, 
        public createAt: string, 
        public user: string, 
    ){}
}








