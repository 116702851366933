export class Integrantes {
    constructor(
        public id_integrante: string,
        public id_comite: string,
        public ApellidoP: string,
        public ApellidoM: string,
        public Nombres: string,
        public Telefono: string,
        public Ocupacion: string,
        public Escolaridad: string,
        public CURP: string,
        public Clave_Electoral: string,
        public urlINE: string,
        public urlAudio: string,
        public urlFoto: string
    ) { }
}









