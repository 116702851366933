import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { RegistrosApp } from '../models/registrosapp';

moment.locale('en');

@Injectable({
  providedIn: 'root'
})

export class RegistrosAppService {

  constructor(private http: HttpClient) { }
  
  getRegistrosAppActivo(correo: String): Observable<RegistrosApp[]> {
    return this.http.get<any>(`${environment.url_api}getUsuarioActivoApp/${correo}`).pipe(
      map(result => result.EstadoAA)
    );
  }

  getRegistrosAppActivoForma2(correo: String){
    let data = {
      correo: correo,
    }

    
    console.log(data);
    
    return this.http.post<any>(`${environment.url_api}getUsuarioActivoApp`, data).pipe(
      map(result => {        
        return result.estado;
      })
    );  
  }

}