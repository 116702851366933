export class Comites {
    constructor(
        public id_comite: string,
        public COT: string,
        public fecha_registro: string,
        public user_c: string,
        public tipo: string,
        public inaugurado: string,
        public activo: string,
        public concepto: string,
        public seccion: string,
        public colonia: string,
        public calle: string,
        public num_ext: string,
        public num_int: string,
        public entre_calle_1: string,
        public entre_calle_2: string,
        public Referencia: string,
        public ApellidoP: string,
        public ApellidoM: string,
        public Nombres: string,
        public Telefono: string,
        public Ocupacion: string,
        public Escolaridad: string,
        public CURP: string,
        public Clave_Electoral: string,
        public urlINE: string,
        public fachadaUrl: string,
        public urlAudio: string,
        public urlFoto: string,
        public latitud: string,
        public longitud: string
    ) { }
}









