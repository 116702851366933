import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { Users } from '../models/users';

moment.locale('en');

@Injectable({
  providedIn: 'root'
})

export class UsersService {

    observable:Observable<Users[]>;  

    constructor(
      private http: HttpClient
    ) {
        
    }
  
    getUsers(): Observable<Users[]> {
      return this.http.get<any>(`${environment.url_api}users`).pipe(map(result => {
        return JSON.parse(JSON.stringify(result.Users)).map(item => {                
          return new Users(
            item.id,
            item.data.email,
            item.data.name,
            item.data.password,
            item.data.zona
          );
        });
      })
      );
    }

    updateUsers(rest: Users) {

        let data = {
          id      : rest.uidUser,
          email   : rest.email,
          name    : rest.name,
          password: rest.password,
          zona : rest.zona,
        }
        console.log(data);
        
        return this.http.put<any>(`${environment.url_api}updateUsers/`, data).pipe(
          map(result => {
            return result;
          })
        );
      }
      

}