import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Comites } from '../models/comites';
import { Integrantes } from '../models/integrantes';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

moment.locale('en');

@Injectable({
  providedIn: 'root'
})

export class ComitesService {


  constructor(private http: HttpClient) { }

  getComites(): Observable<Comites[]> {
    return this.http.get<any>(`${environment.url_api}getComiteAll`).pipe(map(result => {
      return JSON.parse(JSON.stringify(result.comites)).map(item => {
        return new Comites(
          item.id_comite,
          item.COT,
          item.fecha_registro,
          item.user_c,
          item.tipo,
          item.inaugurado,
          item.activo,
          item.concepto,
          item.seccion,
          item.colonia,
          item.calle,
          item.num_ext,
          item.num_int,
          item.entre_calle_1,
          item.entre_calle_2,
          item.Referencia,
          item.ApellidoP,
          item.ApellidoM,
          item.Nombres,
          item.Telefono,
          item.Ocupacion,
          item.Escolaridad,
          item.CURP,
          item.Clave_Electoral,
          item.urlINE,
          item.fachadaUrl,
          item.urlAudio,
          item.urlFoto,
          item.latitud,
          item.longitud,
        );
      });
    })
    );
  }

  getIntegrantesPorComite(idComite: number): Observable<Integrantes[]> {
    return this.http.get<any>(`${environment.url_api}getIntegrantesByComite/${idComite}`).pipe(map(result => {
      return JSON.parse(JSON.stringify(result.Integrantes)).map(item => {
        return new Integrantes(
          item.id_integrante,
          item.id_comite,
          item.ApellidoP,
          item.ApellidoM,
          item.Nombres,
          item.Telefono,
          item.Ocupacion,
          item.Escolaridad,
          item.CURP,
          item.Clave_Electoral,
          item.urlINE,
          item.urlAudio,
          item.urlFoto,
        );
      });
    })
    );
  }

  getIntegrantes(): Observable<Integrantes[]> {
    return this.http.get<any>(`${environment.url_api}getIntegrantesAll`).pipe(map(result => {
      return JSON.parse(JSON.stringify(result.Integrantes)).map(item => {
        return new Integrantes(
          item.id_integrante,
          item.id_comite,
          item.ApellidoP,
          item.ApellidoM,
          item.Nombres,
          item.Telefono,
          item.Ocupacion,
          item.Escolaridad,
          item.CURP,
          item.Clave_Electoral,
          item.urlINE,
          item.urlAudio,
          item.urlFoto,
        );
      });
    })
    );
  }
}